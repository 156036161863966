import { ImageIcon } from '@prism2/icons-react/prism';
import { components, type OptionProps } from 'react-select';

export const Option = (props: OptionProps<{ label: string; value: string }>) => {
  const {
    data: { value },
  } = props;
  return (
    <div className="flex items-center">
      <components.Option {...props} />
      <a data-testid="image-link" target="blank" href={value}>
        <ImageIcon className="h-5 mx-3 color-green text-blue-800" />
      </a>
    </div>
  );
};
