export const GuidedImages = [
  { label: 'VIN', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/sdkGuideVinEmis@3x.png' },
  {
    label: "Front Seats Driver's Side",
    value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreFrontDriversSeat@3x.png',
  },
  {
    label: 'Front Driver Undercarriage',
    value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/uCSedan01DriverFront@3x.png',
  },
  { label: 'All Keys', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/sdkGuideKeys@3x.png' },
  { label: 'Odometer', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreOdometer@3x.png' },
  {
    label: 'Center Console',
    value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreCenterConsoleDisplay@3x.png',
  },
  { label: 'Headliner/Sunroof', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreSunroof@3x.png' },
  { label: 'Roof', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/rightroof2@3x.png' },
  { label: 'Second Row', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreLeftBackSeat@3x.png' },
  { label: '3rd Row Seats', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/van3RdRow@3x.png' },
  { label: 'Inside Trunk', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreTrunk@3x.png' },
  { label: 'Truck Bed', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/sdkGuideTruckGateDown@3x.png' },
  { label: 'SUV Cargo Area', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/vanTrunk@3x.png' },
  { label: 'Charging Cable', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/electricChargerCord@3x.png' },
  { label: 'Rear Undercarriage', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/uCSedan03Rear@3x.png' },
  {
    label: 'Second Row Passenger Side',
    value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/rightRearSeat@3x.png',
  },
  {
    label: 'Rear Passenger Undercarriage',
    value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/uCSedan04PassRear@3x.png',
  },
  {
    label: 'Passenger Seat',
    value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreFrontPassengerSeat@3x.png',
  },
  { label: 'Glovebox', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreGloveCompartment@3x.png' },
  {
    label: 'Front Engine Undercarriage',
    value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/uCSedan06Front@3x.png',
  },
  { label: "Driver's Front 3/4", value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreExtFrontLeft@3x.png' },
  { label: 'Back Left 3/4', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreExtBackLeft@3x.png' },
  { label: 'Passenger Back 3/4', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreExtBackRight@3x.png' },
  { label: 'Passenger Side Profile', value: 'https://int1-fyusion-image-guides.awsgdmownernp.manheim.com/cafs/assets/coreExtRight@3x.png' },
];
